import { HorizontalBar, mixins } from 'vue-chartjs';
import { mapState } from 'vuex';
import clone from 'just-clone';

const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,

  mixins: [reactiveProp],

  props: ['options'],

  computed: {
    ...mapState('settings', ['darkTheme']),
  },

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object

    const chartData = clone(this.chartData);
    // the old way of passing barThickness is deprecated, so we will pass it to datasets array
    // this way we don't need to pass it manually in every component that uses it
    for (let i = 0; i < chartData.datasets.length; i++) {
      chartData.datasets[i].barThickness = 12;
    }

    this.renderChart(chartData, {
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              fontColor: this.darkTheme ? 'white' : 'black',
            },
          },
        ],
        yAxes: [
          {
            // barThickness: 12, // this is the old way of passing barThickness
            ticks: {
              fontColor: this.darkTheme ? 'white' : 'black',
            },
          },
        ],
      },
      legend: {
        labels: {
          fontColor: this.darkTheme ? 'white' : 'black',
        },
      },
    });
  },
};
