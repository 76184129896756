<template>
  <BaseFilterDialogAndChips
    :submitted-values="submittedValues"
    :show-reset-button="showResetButton"
    @open="copyFilterParams"
    @clear:filter="clearFilter"
    @reset="resetFilters"
    @submit="submitFilters"
  >
    <v-row dense>
      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="params.period_start"
          :label="$t('general.date_and_time.from')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput v-model="params.period_end" :label="$t('general.date_and_time.to')" />
      </v-col>
    </v-row>
  </BaseFilterDialogAndChips>
</template>

<script>
import BaseFilterDialogAndChips from '@/components/base/BaseFilterDialogAndChips';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import filterMixin from '@/mixins/filter-mixin';
import { getDefaultProjectIssueStatisticsFilterParams } from '@/store/modules/project-issue-statistics-module';

export default {
  name: 'ProjectsIssueStatisticsFilter',

  components: { BaseDatepickerInput, BaseFilterDialogAndChips },

  mixins: [filterMixin],

  data() {
    return {
      defaultParams: getDefaultProjectIssueStatisticsFilterParams(),
    };
  },

  computed: {
    submittedValues() {
      const values = [];
      const { period_start, period_end } = this.filterParams;

      if (period_start) {
        values.push({
          key: 'period_start',
          value: period_start,
          label: this.$t('general.date_and_time.from'),
        });
      }

      if (period_end) {
        values.push({
          key: 'period_end',
          value: period_end,
          label: this.$t('general.date_and_time.to'),
        });
      }

      return values;
    },
  },
};
</script>

<style scoped></style>
