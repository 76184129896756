<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ProjectsIssueStatisticsFilter
        :filter-params="projectIssueStatisticsFilterParams"
        @change="$router.push({ name: 'projectIssueStatistics', query: $event })"
        @reset="resetProjectsIssueStatisticsFilters"
      />
    </div>

    <div :class="{ 'content-loading': loading['get:api/project-issues-statistic'] }">
      <div class="pa-3">
        <HorizontalBarChart :chart-data="countsChartData" />
      </div>

      <div class="pa-3">
        <HorizontalBarChart :chart-data="timesChartData" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsIssueStatisticsFilter from '@/components/filters/ProjectsIssueStatisticsFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import round from '@/util/numbers';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import { getDefaultProjectIssueStatisticsFilterParams } from '@/store/modules/project-issue-statistics-module';

export default {
  name: 'ProjectsStatisticsIssues',

  components: { HorizontalBarChart, ProjectsIssueStatisticsFilter },

  computed: {
    ...mapState('projectIssueStatistics', [
      'projectIssueStatistics',
      'projectIssueStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),

    countsChartData() {
      const labels = [];
      const solvedIssueCounts = [];
      const unsolvedIssueCounts = [];

      for (let i = 0; i < this.projectIssueStatistics.length; i++) {
        const project = this.projectIssueStatistics[i];

        labels.push(project.project_name || '-');
        solvedIssueCounts.push(
          +project.solved_normal + +project.solved_important + +project.solved_critical
        );
        unsolvedIssueCounts.push(
          +project.unsolved_normal + +project.unsolved_important + +project.unsolved_critical
        );
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('projects.labels.solved'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: solvedIssueCounts,
          },
          {
            label: this.$t('projects.labels.unsolved'),
            backgroundColor: this.$vuetify.theme.currentTheme.error,
            data: unsolvedIssueCounts,
          },
        ],
      };
    },

    timesChartData() {
      const labels = [];
      const seeingTimes = [];
      const solvingTimes = [];

      for (let i = 0; i < this.projectIssueStatistics.length; i++) {
        const project = this.projectIssueStatistics[i];

        labels.push(project.project_name || '-');
        seeingTimes.push(round(project.average_seen_in_minutes / 60, 1));
        solvingTimes.push(round(project.average_solved_in_minutes / 60, 1));
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('projects.labels.average_issue_seeing_time'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: seeingTimes,
          },
          {
            label: this.$t('projects.labels.average_issue_solving_time'),
            backgroundColor: this.$vuetify.theme.currentTheme.error,
            data: solvingTimes,
          },
        ],
      };
    },
  },

  created() {
    this.fetchProjectIssueStatistics(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.projectIssueStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'projectIssueStatistics'
    ) {
      this.fetchProjectIssueStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('projectIssueStatistics', ['fetchProjectIssueStatistics']),

    resetProjectsIssueStatisticsFilters() {
      const defaultFilters = getDefaultProjectIssueStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) === JSON.stringify(this.projectIssueStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'projectIssueStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped></style>
